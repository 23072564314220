import { useEffect, useRef } from 'react';
import videojs from "video.js";
import axios from 'axios';
import axiosRetry from 'axios-retry';
import moment from 'moment';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

/**
 * Function that receives a videojs player and gets the first segment to be player
 * @param  {videojs object} player
 */
const getSegmentPDT = (player) => {
  try {
    // Pass and argument on tech to silent the warning messages
    return player.tech('s').vhs.playlists.media().segments[0].dateTimeObject;
  } catch (error) {
    return undefined
  }
};

/**
 * Function that receives a URL player and gets the PDT of that stream
 * @param  {videojs object} player
 */
const getStreamPDT = async (URL) => {
  let fullUrl = '';
  let dateTime = moment();
  axiosRetry(axios, { retries: 3 });
  await axios.get(URL).then((response) => {
    const splitted = response.data.split("\n");
    let value = '';
    splitted.some((split) => {
      value = split;
      return split.substring(split.length - 4) === "m3u8";
    });

    if (value.slice(0, 4) !== "http") {
      fullUrl = URL.substr(0, URL.lastIndexOf("/")) + "/" + value;
    } else {
      fullUrl = value;
    }
  })
  await axios.get(fullUrl).then((r) => {
    const splitted = r.data.split("\n");
    for (let j = 0; j < splitted.length; j++) {
      if (splitted[j].includes('EXT-X-PROGRAM-DATE-TIME')) {
        dateTime = moment(splitted[j].substring(splitted[j].indexOf(':') + 1));
        break;
      }
    }
  })
  return dateTime;
}

/**
 * Function that calculates the EVT (Efective Visual Time) for the received player and returns it
 * @param  {videojs object} player
 * @param  {array} safariProgramDateTime
 * @param  {int} useLogs
 */
const getEVT = (player, safariProgramDateTime, useLogs) => {
  let EVT = 0;
  const minute = 60000; // Minute in ms
  const currentTime = player.currentTime();
  const seekableStart = player.liveTracker.seekableStart();
  let programDateTime = moment(getSegmentPDT(player));
  if (videojs.browser.IS_ANY_SAFARI && player.id() === 'sprite-video') {
    programDateTime = safariProgramDateTime;
  }
  EVT = programDateTime.valueOf() + (currentTime * 1000) - minute - (seekableStart * 1000);
  if (useLogs === "0" || useLogs === "1" || useLogs === "3")
    console.table({
      'player': player.id(),
      'EVT': moment(EVT).format(),
      'PDT': moment(programDateTime).format(),
      'speed': player.el().querySelector('video').playbackRate,
      'current time': player.currentTime(),
      'seekable start': player.liveTracker.seekableStart(),
    });
  return EVT;
};


/**
* Check if the player is actually playing
* @param  {videojs object} player
*/
const isPlaying = (player) => {
  const playing = player ? player.currentTime() > 0 && !player.paused() && !player.ended() && player.readyState() > 2 : false;
  return playing;
}

/**
 * Function that calculates the offset between two players based on the EVT of each player
 * @param  {videojs object} player1
 * @param  {videojs object} player2
 */
const calculateOffset = (player1, player2, safariProgramDateTimes, useLogs) => {
  let EVT1 = getEVT(player1, safariProgramDateTimes[0], useLogs);
  let EVT2 = getEVT(player2, safariProgramDateTimes[1], useLogs);
  let offset = (EVT1 - EVT2) / 1000;
  return offset;
};
/**
 * Function that shall cause the calling thread to be suspended from execution until either the number of realtime ms specified by the argument ms has elapsed
 * @param  {int} ms
 */
function sleep(ms) {
  return new Promise(
    resolve => setTimeout(resolve, ms)
  );
};

/**
 * Function that receives two videojs players and synchronise both of them based on the offset and which one should be playing next
 * @param  {videojs object} player1
 * @param  {videojs object} player2
 * @param  {boolean} speed if the player sync should by by jumping the time, or increasing/decreasing the time
 */
const synchroniseStreams = async (player1, player2, speed, useLogs) => {
  if (useLogs === "0" || useLogs === "1" || useLogs === "5")
    console.time('Sync time')
  let speeds = [0.9, 1.1];
  if (player1.id() === 'bsl-video' || player1.id() === 'subtitle-video' || player1.id() === 'sprite-video') {
    speeds = [0.5, 1.5];
  }
  // If streams are live (We aren't on the demo)
  if (window.location.pathname != "demo" && window.location.pathname != "/demo") {
    // Get the PDT for both players
    const player1PDT = await getStreamPDT(player1.src());
    const player2PDT = await getStreamPDT(player2.src());
    let safariProgramDateTimes = [player1PDT, player2PDT];
    let offsetError = 0.3;
    // Calculate the offset
    let offset = calculateOffset(player1, player2, safariProgramDateTimes, useLogs);
    while (Math.abs(offset) >= offsetError && (isPlaying(player1) && isPlaying(player2))) {
      if (useLogs === "0" || useLogs === "1" || useLogs === "2" || useLogs === "5") console.log(`Syncing ${player1.id()} to ${player2.id()}`);
      if (speed) {
        const playbackRate = offset > 0 ? speeds[0] : speeds[1];
        player1.el().querySelector('video').playbackRate = playbackRate;
        offset = calculateOffset(player1, player2, safariProgramDateTimes, useLogs);
        if (useLogs === "0" || useLogs === "1" || useLogs === "2")
          console.table({
            'action': offset > 0 ? 'Slower!' : 'Quicker!',
            'offset': offset,
            'offset error': offsetError,
            'playbackRate': player1.el().querySelector('video').playbackRate,
          });
        await sleep(500);
      }
      else {
        player1.currentTime(player1.currentTime() - offset);
        await sleep(100);
        offset = calculateOffset(player1, player2, safariProgramDateTimes, useLogs);
        offsetError += 0.1;
        if (useLogs === "0" || useLogs === "1" || useLogs === "2")
          console.table({
            'action': offset > 0 ? 'Backwards!' : 'Forward!',
            'offset': offset,
            'offset error': offsetError,
          });
        await sleep(500);
      }
      if (useLogs === "0" || useLogs === "1" || useLogs === "3")
        console.table({
          'offset': offset,
          'offset error': offsetError,
        });
    }
    if (speed) {
      player1.el().querySelector('video').playbackRate = 1;
    }
  }
  // If streams aren't live (We are on the demo)
  else {
    let offset = player1.currentTime() - player2.currentTime();
    if (Math.abs(offset) > 0.3 && (isPlaying(player1) && isPlaying(player2))) {
      if (speed) {
        while (Math.abs(offset) >= 0.3) {
          const playbackRate = offset > 0 ? 0.9 : 1.1;
          player1.el().querySelector('video').playbackRate = playbackRate;
          offset = player1.currentTime() - player2.currentTime();
          await sleep(500);
        }
        player1.el().querySelector('video').playbackRate = 1;
      } else {
        while (Math.abs(offset) >= 0.3) {
          const playbackRate = offset > 0 ? 0.9 : 1.1;
          player1.currentTime(player1.currentTime() - offset)
          offset = player1.currentTime() - player2.currentTime();
          await sleep(500);
        }
      }
    }
  }
  if (useLogs === "0" || useLogs === "1" || useLogs === "5")
    console.timeEnd('Sync time')
};

/**
 * Function that receives an HTML element and recursively checks his parents to see if they have the specified class
 * @param  {HTML element} element
 * @param  {string} classname
 */
const hasSomeParentTheClass = (element, classname) => {
  if (typeof (element.className ? element.className : 'no').includes === "function" && (element.className ? element.className : 'no').includes(classname)) return true;
  return element.parentNode && hasSomeParentTheClass(element.parentNode, classname);
};

/**
 * Function that pushes data to the dataLayer so GTM can read it
 * @param  {dictionary} eventData
 */
const addToDataLayer = (eventData) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(eventData)
  }
};


const calculateShrinkSize = () => {
  const domMultiView = document.querySelector('.MV');
  let videoInterface = document.getElementById('video-interface');
  let domBSL = videoInterface.querySelector('.bsl');
  let domCaptions = videoInterface.querySelector('.subtitles');
  let domBottomInterface = videoInterface.querySelector('.bottom-interface');
  let domPortraitMessage = videoInterface.querySelector('.portrait-warning');
  let shrink = 'false';
  let shrinkbsl = 'false';
  if (domMultiView) {
    shrink = domMultiView.getAttribute('data-shrink');
  }
  if (domBSL) {
    shrinkbsl = domBSL.getAttribute('data-shrink');
  }
  if (shrink === 'true' || shrinkbsl === 'true') {
    let domPlayers = [videoInterface.querySelector('#player1-wrapper'), videoInterface.querySelector('#player2-wrapper')];
    domBSL.style.top = 0 + "px";
    domBSL.style.left = 0 + "px";
    domCaptions.style.top = 0 + "px";
    let reduceWidth = 0;
    const BSLEnabled = domBSL.getAttribute('data-enabled');
    const CaptionsEnabled = domCaptions.getAttribute('data-enabled');
    const BIEnabled = domBottomInterface.getAttribute('data-enabled'); // Bottom Interface
    if ((BSLEnabled === 'true' && (
      (CaptionsEnabled === "false" && BIEnabled === "false") ||
      (CaptionsEnabled === "true" && BIEnabled === "false") ||
      (CaptionsEnabled === "false" && BIEnabled === "true")
    ))) {
      reduceWidth = domBSL.offsetWidth;
    } else if (BSLEnabled === 'false' && CaptionsEnabled === "true" && BIEnabled === "false") {
      reduceWidth = domCaptions.offsetHeight * (16 / 9);
    } else if (BIEnabled === "true" && CaptionsEnabled === "true" && (BSLEnabled === 'false' || BSLEnabled === 'true')) {
      reduceWidth = (domCaptions.offsetHeight + domBottomInterface.offsetHeight) * (16 / 9)
    } else if (BIEnabled === "true" && CaptionsEnabled === "false" && BSLEnabled === 'false') {
      reduceWidth = domBottomInterface.offsetHeight * (16 / 9);
    }

    domPlayers.forEach(player => {
      player.style.width = parseInt(videoInterface.offsetWidth - (reduceWidth)) + "px";
      player.style.top = parseInt((CaptionsEnabled === 'true') ? domCaptions.offsetHeight : 0) + "px";
      player.style.left = parseInt((BSLEnabled === 'true') ? domBSL.offsetWidth : 0) + "px";
      player.style.transform = "translateX(0%)";
      if (BSLEnabled === 'false' && ((BIEnabled === 'true') || CaptionsEnabled === 'true')) {
        player.style.left = "50%";
        player.style.transform = "translateX(-50%)"
        domCaptions.style.left = "50%";
        domCaptions.style.transform = "translateX(-50%)"
      }
    });
  } else {
    const properties = ['top', 'left', 'transform', 'width']
    properties.forEach(property => {
      domCaptions.style.removeProperty(property);
      domBSL.style.removeProperty(property);
      videoInterface.querySelector('#player1-wrapper').style.removeProperty(property);
      videoInterface.querySelector('#player2-wrapper').style.removeProperty(property);
    });
  }
}

// Paint camera layout
// Part 1
//  Get the number of cameras
//  Split the number of cameras between the max number desired per row. In this case 7 cameras per row
//  Get the number of rows (max 2)
//  Split the number of cameras between the obtained rows (1 or 2) to get split them evenly
//  Set the flex-basis based on the number of cameras per row obtained (previous step)
// Part 2
//  Get the width of the camera-buttons and height of the bottom-interface
//  If there are two rows split the height in two and subtract the borders of the cameras
//  Adjust the width of each camera to maintain aspect ratio

function paintCameraLayout() {
  const cameraButtons = document.querySelector('.camera-buttons');
  const bottomInterface = document.querySelector('.bottom-interface');
  const nCameras = cameraButtons.dataset.cols; //  Get the number of cameras
  if (window.innerHeight < window.innerWidth) {
    if (nCameras == 0) {
      setTimeout(paintCameraLayout, 500);
    } else {
      const nRows = Math.ceil(nCameras / 7) >= 2 ? 2 : 1; //  Split the number of cameras between the max number desired per row. In this case 7 cameras per row
      const camerasPerRow = Math.ceil(nCameras / nRows); //  Split the number of cameras between the obtained rows(1 or 2) to get split them evenly
      const cameraArray = document.querySelectorAll('.camera');
      cameraArray.forEach((camera) => {
        camera.style.flexBasis = ((cameraButtons.offsetWidth / camerasPerRow) - 12 + 'px');
        camera.style.height = (camera.offsetWidth / (16 / 9)) + "px";
      });
    }
  } else {
    const cameraArray = document.querySelectorAll('.camera');
    cameraArray.forEach((camera) => {
      camera.style.flexBasis = '';
      camera.style.height = '';
      const cameraCanvas = camera.querySelector('.camera-canvas');
      cameraCanvas.style.height = (camera.offsetWidth / (16 / 9)) + "px";
    });
  }
}

// const cameraCanvasArray = document.querySelectorAll('.camera-canvas');
// cameraCanvasArray.forEach((camera) => {
//   camera.style.height = (camera.offsetWidth / (16 / 9)) + "px";
// });

export { getSegmentPDT, getStreamPDT, getEVT, synchroniseStreams, isPlaying, addToDataLayer, hasSomeParentTheClass, useInterval, sleep, calculateShrinkSize, paintCameraLayout };
