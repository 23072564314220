import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import videojs from "video.js";

import Loader from "react-loader-spinner";
import { useLocation } from "@reach/router";
import queryString from 'query-string';
import { useIntercom } from 'react-use-intercom';

import { ChatIcon } from "../image";
import VideoInterface from '../Videointerface';
import { Context as VideoPlayerContext } from '../../context/VideoplayerContext';
import { useInterval } from '../../helpers/helpers';
const Body = (props) => {
    //   Get the variables and functions from context
    const {
        state: {
            showId,
            showName,
            displayName,
            performances,
            serverError,
            streams,
            reportUsageInterval,
            ticketSessionId,
            serverTime,
            isSynced
        },
        getShow,
        getStream,
        getPerformance,
        reportUsage,
        redeemTicket,
        getServerTime
    } = useContext(VideoPlayerContext);
    const { boot, shutdown, hide, show, update } = useIntercom();

    const location = useLocation();
    const [modalIsOpen, setModalIsOpen] = useState(true); // Show or hide the modal
    const [openTime, setOpenTime] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [currentTime, setCurrentTime] = useState(null);
    const [isStarted, setIsStarted] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [performance, setPerformance] = useState("");
    const [performanceID, setPerformanceID] = useState(""); // Performance
    const [ticketID, setTicketID] = useState(null); // Ticked ID
    const [reportUsageTimer, setReportUsageTimer] = useState(null);
    const [modalMessage, setModalMessage] = useState('');
    const [isTesting, setIsTesting] = useState(false);
    const [internalError, setInternalError] = useState(false);
    const [useLogs, setUseLogs] = useState(0); // Allow logging to be displayed. If 0 don't show any logging.
    const [isSafari, setIsSafari] = useState(false);
    let password = '';
    let chrome = 'https://www.google.com/intl/en_uk/chrome/';
    let firefox = 'https://www.mozilla.org/en-GB/firefox/new/';
    let edge = 'https://www.microsoft.com/en-us/edge';
    let safariWarning = `<p>We\'ve spotted you\'re using a Safari browser. That works but for the best experience please try
    <a href="${chrome}" target="_blank" rel="noopener noreferrer">Chrome</a>, 
    <a href="${firefox}" target="_blank" rel="noopener noreferrer">Firefox</a>,
    or <a href="${edge}" target="_blank" rel="noopener noreferrer">Edge</a></p>`
    useEffect(() => {
        boot({ alignment: 'left' });
        if (queryString.parse(location.search)['p']) {
            // Check if ticket and performance query parameters exist in the URL
            password = queryString.parse(location.search)['p'];
            setIsTesting(true);
        }
        let t = localStorage.getItem('ticket');
        let p = localStorage.getItem('performance');
        const showNameURL = location.pathname.substr(1);
        if (queryString.parse(location.search)['logging']) {
            const logsValue = queryString.parse(location.search)['logging'];
            setUseLogs(logsValue);
            switch (logsValue) {
                case '0': // Show all logs
                    console.log('Logging enabled. Showing all logs');
                    break;
                case '1': // Show sync logs (all)
                    console.log('Logging enabled. Showing all syncing logs');
                    break;
                case '2': // Show sync logs
                    console.log('Logging enabled. Showing stream syncing logs');
                    break;
                case '3': // Show sync logs
                    console.log('Logging enabled. Showing offset and EVT logs');
                    break;
                case '4': // Show interface state logs
                    console.log('Logging enabled. Showing interface states (camera switches, buttons clicked, etc)');
                    break;
            }
        }
        if (showNameURL === 'demo' || showNameURL === '3b-test' || showNameURL === 'test' || password !== '') {
            setIsTesting(true);
            getShow(showNameURL).then(res => {
                if (!res) {
                    console.log('Error!');
                    setInternalError(true);
                    setModalMessage('You need a ticket to watch this broadcast. Please visit <a href="https://www.youngvic.org/">YoungVic.org</a> or contact Young Vic Box Office on <a href="mailto:boxoffice@youngvic.org">boxoffice@youngvic.org</a> to purchase a ticket.');
                }
            });
        } else {
            if (p && t) {
                // Get show data
                getPerformance(p).then((response) => {
                    if (response.name !== showNameURL) {
                        setModalMessage('You need a ticket to watch this broadcast. Please visit <a href="https://www.youngvic.org/">YoungVic.org</a> or contact Young Vic Box Office on <a href="mailto:boxoffice@youngvic.org">boxoffice@youngvic.org</a> to purchase a ticket.');
                        getShow(showNameURL);
                    } else {
                        // If ticket id and performance id exits then ticket can be redeemed
                        redeemTicket(p, t);
                    }
                });

                // Save ticket id and performance id
                setPerformanceID(p);
                setTicketID(t);
            } else {
                setModalMessage('You need a ticket to watch this broadcast. Please visit <a href="https://www.youngvic.org/">YoungVic.org</a> or contact Young Vic Box Office on <a href="mailto:boxoffice@youngvic.org">boxoffice@youngvic.org</a> to purchase a ticket.');
            }
        }
        getServerTime().catch(err => {
            setCurrentTime(moment(new Date()));
        });
    }, []);

    useEffect(() => {
        let message = '';
        // let warning = (videojs.browser.IS_ANY_SAFARI || videojs.browser.IS_IOS) ? ' and please ensure that you have all unused browser tabs and apps closed down.' : ' For best viewing results please ensure that you have all unused browser tabs and apps closed down.';

        if (videojs.browser.IS_ANY_SAFARI || videojs.browser.IS_IOS) {
            setIsSafari(true);
        }
        // Set modal message based on the returned error
        
        switch (serverError) {
            case 0:
                if (isOpen) {
                    message = `${props.sheetsNode.introText}.`;
                } else {
                    if (startTime && displayName) {
                        message = `You've bought a ticket for the ${displayName} on ${printDate(startTime)}. The stream will open 30 minutes before the performance. Please join early to allow time for trouble-shooting any technical issues. From wherever you’re watching, we hope you enjoy the show.`;
                    }
                }
                // message = (message === '' ? warning : `${message}`) + warning;
                setModalMessage(message);
                break;
            case 1: // 1 = PerformanceNotLinkedWithTicketing

                // please contact Young Vic Box Office on <a href="mailto:boxoffice@youngvic.org">boxoffice@youngvic.org</a>
                setModalMessage('There is an error with your broadcast link. To resolve the issue please contact Young Vic Box Office on <a href="mailto:boxoffice@youngvic.org">boxoffice@youngvic.org</a> and quote Error number: 1.');
                break;
            case 2: // 2 = ExternalTicketingApiError
                setModalMessage('There is an error with your broadcast link. To resolve the issue please contact Young Vic Box Office on <a href="mailto:boxoffice@youngvic.org">boxoffice@youngvic.org</a> and quote Error number: 2.');
                break;
            case 3: // 3 = TokenInvalid
                setModalMessage('There is an error with your broadcast link. To resolve the issue please contact Young Vic Box Office on <a href="mailto:boxoffice@youngvic.org">boxoffice@youngvic.org</a> and quote Error number: 3.');
                break;
            case 4: // 4 = PerformanceInvalid
                setModalMessage('There is an error with your broadcast link. To resolve the issue please contact Young Vic Box Office on <a href="mailto:boxoffice@youngvic.org">boxoffice@youngvic.org</a> and quote Error number: 4.');
                break;
            case 5: // 5 = TokenAtMaxRedemptions
                setModalMessage('Someone else is using this broadcast link. You are no longer able to access the broadcast.');
                break;
            case 6:
                setModalMessage('You need a ticket to watch this broadcast. Please visit <a href="https://www.youngvic.org/" target="_blank" rel="noopener noreferrer">YoungVic.org</a> or contact Young Vic Box Office on <a href="mailto:boxoffice@youngvic.org">boxoffice@youngvic.org</a> to purchase a ticket.');
                break;
            default:
                if (serverError) {
                    setModalMessage('You need a ticket to watch this broadcast. Please visit <a href="https://www.youngvic.org/" target="_blank" rel="noopener noreferrer">YoungVic.org</a> or contact Young Vic Box Office on <a href="mailto:boxoffice@youngvic.org">boxoffice@youngvic.org</a>');
                } else {
                    if (isTesting) {
                        message = `${props.sheetsNode.introText}`;
                        //if (videojs.browser.IS_ANY_SAFARI || videojs.browser.IS_IOS) {
                        //    message = (message === '' ? '' : `${message} `) + safariWarning;
                        //}
                        // message = (message === '' ? warning : `${message}`) + warning;
                        setModalMessage(message);
                    }
                }
        }

        //if (videojs.browser.IS_ANY_SAFARI || videojs.browser.IS_IOS) { setModalMessage(modalMessage + safariWarning); }
        
    }, [serverError, performance]);

    const handleLiveChatClick = (e) => {
        show()
    };

    // When the reportUsageInterval and ticketSessionId are set then set an interval that will report the usage
    useEffect(() => {
        if (serverTime) {
            setCurrentTime(moment(serverTime));
        }
    }, [serverTime]);

    useEffect(() => {
        if (reportUsageTimer === null && reportUsageInterval && ticketSessionId) {
            reportUsage(ticketSessionId);
            const timer = setInterval(() => {
                reportUsage(ticketSessionId); // Post data to API
            }, reportUsageInterval * 1000); // The data sent is in seconds, so it has to be converted to ms (x1000)
            setReportUsageTimer(timer);
        }
    }, [reportUsageInterval, ticketSessionId])

    // When the performances are fetched then look for the right one and save it to be able to fetch the configurations
    useEffect(() => {
        if (!isTesting) {
            if (!performance && performances && performances.length > 0 && performanceID !== "") {
                performances.map((item) => {
                    if (item.ticketingId == performanceID) {
                        setPerformance(item);
                    }
                })
            }
        } else {
            if (!performance && performances && performances.length > 0) {
                if (serverTime) {
                    const first = getFirst(serverTime, performances);
                    setPerformance(first);
                    if (queryString.parse(location.search)['p']) {
                        // Check if ticket and performance query parameters exist in the URL
                        password = queryString.parse(location.search)['p'];
                    }
                    getStream(first.id + (password !== '' ? ('?p=' + password) : ''));
                }
            }
        }
    }, [performances, performanceID, serverTime]);

    const getFirst = (num, arr) => {
        let curr = arr[0];
        
        arr.forEach((val) => {
            if (new Date(val.openTime) < new Date(curr.openTime)) {
                curr = val
            }});

        return curr;
    }
    // When the performance has been fetch then set the open time and start time as states for future checks
    useEffect(() => {
        if (performance) {
            setOpenTime(moment(performance.openTime));
            setStartTime(moment(performance.startTime));
        }
    }, [performance]);

    useEffect(() => {
        if (currentTime && openTime && currentTime.isAfter(openTime)) {
            setIsOpen(true);
        }
        if (currentTime && startTime && currentTime.isAfter(startTime)) {
            setIsStarted(true);
        }
    }, [startTime, openTime, currentTime]);

    // Check every 5 seconds if the show is open or started
    useInterval(() => {
        if (currentTime) {
            setCurrentTime(moment(currentTime).add(5, 'seconds'));
        } else {
            setCurrentTime(moment.utc(new Date()));
        }
    }, (!isStarted && !isOpen) ? 5000 : null);

    // Function that formats the date to be displayed
    const printDate = (date) => {
        return `${moment(date).format('dddd Do MMMM YYYY, HH:mm')}`;
    }

    // Function that closes the modal. Only allow to close the modal if the error is equal to 0 and the performance is open
    function closeModal() {
        if (isOpen && performance && (isTesting || (ticketID && (serverError === 0))) && isSynced) {
            if (useLogs === "0" || useLogs === "4") console.log('Closing modal');
            setModalIsOpen(false);
        } else if (isTesting) {
            setModalIsOpen(false);
        }
    }

    return (
        <>
            {((isOpen && serverError === 0) || (isTesting && performance)) &&
                <>
                    <div className="scroll-down">
                        <h1>Scroll down to watch the show</h1>
                    </div>
                    <VideoInterface
                        modalIsOpen={modalIsOpen}
                        showId={showId}
                        showName={showName}
                        displayName={displayName}
                        performance={performance}
                        isStarted={isStarted}
                        sheetsNode={props.sheetsNode}
                        streams={isTesting ? streams : streams.streams}
                        useLogs={useLogs}
                    />
                </>
            }
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Welcome"
                ariaHideApp={false}>
                {!internalError ?
                    <>
                        <div className="logo">
                            <img src={props.sheetsNode.logoImage} alt="" />
                        </div>
                        <div className="modal-content">
                            {(serverError === 0 || (isTesting && performance)) &&
                                <h2 className="welcome-title">{displayName}</h2>
                            }
                            {(!modalMessage && modalMessage === '') ?
                                <Loader
                                    type="TailSpin"
                                    color="#ffc700"
                                    height={50}
                                    width={50}
                                />
                                :
                                <div className="modal-text" dangerouslySetInnerHTML={{ __html: (isSafari ? safariWarning : '') + modalMessage }}></div>
                            }
                            {isSynced ?
                                <>
                                    {((isOpen && serverError === 0) || (isTesting && performance)) &&
                                        <button onClick={closeModal} className="modal-button">Enter the {displayName}</button>
                                    }
                                </>
                                : <>
                                    {((isOpen && serverError === 0) || (isTesting && performance)) &&
                                        <Loader
                                            type="TailSpin"
                                            color="#ffc700"
                                            height={25}
                                            width={25} />
                                    }
                                </>
                            }


                            <button id='live-chat' className="live-chat-link button" onClick={handleLiveChatClick}>
                                <span className="icon">
                                    <ChatIcon />
                                    <span>Live Support</span>
                                </span>
                            </button>
                        </div>
                    </>
                    :
                    <>
                        <div className="logo">
                            <img src="https://res.cloudinary.com/b3/image/upload/co_rgb:FDBF2D,e_colorize:100,f_auto,q_auto/v1619692769/YV_Logo_YELLOWPNG-Large.png" alt="" />
                        </div>
                        <div className="modal-content">
                            <div className="modal-text" dangerouslySetInnerHTML={{ __html: (isSafari ? safariWarning : '') + modalMessage }}></div>
                        </div>
                    </>
                }
            </Modal>
        </>
    )
}

export default Body;
