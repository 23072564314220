import videojs from "video.js";
// Video processor to split the sprite stream
export let Processor = {
    // Recursive function that checks if the video is paused if not computes the next frame
    timerCallback: function () {
        this.computeFrame(); // Compute next frame
        let self = this; // Save scope of the function to call the function inside of the timeout function
        setTimeout(function () {
            self.timerCallback(); // Recursive call
        }, (videojs.browser.IS_ANY_SAFARI || videojs.browser.IS_IOS) ? 5000 : isNaN(this.framerate) ? 0 : 1000 / (this.framerate === 0 ? 1 : this.framerate)); // Time could be increased to reduce the number of frames computed (Helps to improve performance)
    },

    // Main function that sets the player to crop and gets all the canvas where the crops should go and their context
    doLoad: function (player) {
        this.video = player.children_[0];
        this.framerate = !(videojs.browser.IS_ANY_SAFARI || videojs.browser.IS_IOS || (videojs.browser.IS_FIREFOX && videojs.browser.IS_ANDROID)) ? player.tech('s').vhs.playlists.media().attributes['FRAME-RATE'] - 0 : 0;
        this.canvasArray = document.querySelectorAll("canvas"); // Get all the canvas 
        this.canvasContext = [];
        this.canvasArray.forEach(canvas => { // Save the context and DOM for each canvas
            const ctx = canvas.getContext("2d");
            this.canvasContext.push({ c: canvas, ctx: ctx });
        });
        this.timerCallback(); // Once everything is set start the recursive calls
    },
    // Function that computes each frame
    computeFrame: function () {
        const videoWidth = this.video.videoWidth; // Get the video width
        const videoHeight = this.video.videoHeight; // Get the video height
        this.canvasContext.forEach((canvas, index) => { // For each canvas set the proper cropped fraction of the sprite
            const sx = videoWidth * canvas.c.dataset.width; // Calculate the width to crop
            const sy = videoHeight * canvas.c.dataset.height; // Calculate the height to crop
            if (sx !== 0 && sy !== 0) {
                canvas.ctx.drawImage( // Draw the image on the canvas
                    this.video, // The source where it should get the image
                    videoWidth * canvas.c.dataset.positionx, // The X position where it should start cropping
                    videoHeight * canvas.c.dataset.positiony, // The Y position where it should start cropping
                    sx, // The width to crop
                    sy, // The height to crop
                    0, // The X position inside the canvas
                    0, // The Y position inside the canvas
                    256, // The width of the canvas (For resizing purposes)
                    144 // The height of the canvas (For resizing purposes)
                );
            }
        });
        return;
    }
};