import React, { useState } from 'react';
import { IntercomProvider } from 'react-use-intercom';
// import Header from '../component/layout/Header';
import Body from '../component/layout/Body';
// import Footer from '../component/layout/Footer';

import LogRocket from 'logrocket';

import { Provider as VideoplayerProvider } from '../context/VideoplayerContext';
import "../styles/styles.scss";

// markup
const Show = (data) => {
    const { pageContext } = data;
    const { show } = pageContext;
    const [sheetsNode, setSheetsNode] = useState(show);
    LogRocket.init('2hggbr/rivver');
    return (
        <IntercomProvider appId={'kq4qp3f1'}>
            <VideoplayerProvider>
                <main>
                    {sheetsNode &&
                        <>
                            {/* <Header /> */}
                            <Body sheetsNode={sheetsNode} />
                            {/* <Footer /> */}
                        </>
                    }
                </main>
            </VideoplayerProvider>
        </IntercomProvider>
    )

}
export default Show;