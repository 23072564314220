import React, { useState, useEffect, useContext } from "react";
import videojs from "video.js";
import { useIdleTimer } from 'react-idle-timer';
import Modal from 'react-modal';
import { useIntercom } from 'react-use-intercom';
import { useLocation } from "@reach/router";

import Loader from "react-loader-spinner";
import { CloseIcon, SettingsIcon, SoundOnIcon, SoundOffIcon, PinIcon, UnpinIcon, ChatIcon, CaptionsIcon, BSLIcon, ADIcon, FSIcon, FullscreenOnIcon, FullscreenOffIcon} from "./image";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { Context as VideoPlayerContext } from "../context/VideoplayerContext";
import { synchroniseStreams, isPlaying, addToDataLayer, hasSomeParentTheClass, useInterval, calculateShrinkSize, paintCameraLayout } from '../helpers/helpers';
import { Processor } from '../helpers/processor';
import videojsConfig from "../config/videojs-config.json"

require('videojs-contrib-quality-levels');

const VideoInterface = (props) => {
  // Get the variables and functions from context
  const {
    state: {
      initialStreamIndex,
      programStreamIndex,
      buttonsStreamIndex,
      subtitleStreamIndex,
      signingStreamIndex,
      buttons,
      alternateAudioStreams,
    },
    getPerformanceConfiguration,
    checkSync
  } = useContext(VideoPlayerContext);

  const location = useLocation();
  // Functional states
  const [streams, setStreams] = useState([props.streams]);
  const { boot, shutdown, hide, show, update } = useIntercom();
  // Player control (Show or hide)
  const [mainVideoPlay, setMainVideoPlay] = useState(true); // Show or hide the main player
  const [secondVideoPlay, setSecondVideoPlay] = useState(false); // Show or hide the second player
  const [toggleCaptions, setToggleCaptions] = useState(false); // Show or hide captions
  const [toggleBSL, setToggleBSL] = useState(false); // Show or hide BSL
  const [toggleAudio, setToggleAudio] = useState(false); // Show or hide Audio

  // Player states (VideoJS objects)
  const [player1, setPlayer1] = useState(undefined); // VideoJS object main player
  const [player2, setPlayer2] = useState(undefined); // VideoJS object second player to make the camera switch
  const [subtitlePlayer, setSubtitlePlayer] = useState(undefined); // VideoJS object Subtitle player
  const [BSLPlayer, setBSLPlayer] = useState(undefined); // VideoJS object BSL player
  const [spritePlayer, setSpritePlayer] = useState(undefined);
  const [audioPlayer, setAudioPlayer] = useState(undefined);

  const [qualityLevels, setQualityLevels] = useState([]);
  const [qualityLevel, setQualityLevel] = useState('Auto');
  const [selectedQuality, setSelectedQuality] = useState('Auto'); // Used to play all the players with the same quality

  const [isEnded, setIsEnded] = useState(false);
  const [mutePlayers, setMutePlayers] = useState(false);
  const [muteAll, setMuteAll] = useState(false);
  const [isInFullScreen, setIsInFullScreen] = useState(false);
  // Set the active state for the camera buttons
  const [playingCameraIndex, setPlayingCameraIndex] = useState(0);

  // Show or hide mobile controls
  const [toggleMobileControls, setToggleMobileControls] = useState(false);
  const [isToggleOn, setIsToggleOn] = useState(false);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentBSLDate, setCurrentBSLDate] = useState(new Date());
  const [currentCaptionsDate, setCurrentCaptionsDate] = useState(new Date());
  const [currentAudioDate, setCurrentAudioDate] = useState(new Date());
  const [currentQualityDate, setCurrentQualityDate] = useState(new Date());
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [showBSLSpinner, setShowBSLSpinner] = useState(false);
  const [showCaptionsSpinner, setShowCaptionsSpinner] = useState(false);

  // Interface states
  const [showInterface, setShowInterface] = useState(true);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showCameras, setShowCameras] = useState(true);
  const [pinCameras, setPinCameras] = useState(false);
  const [simpleView, setSimpleView] = useState(false);
  const [simpleViewButtons, setSimpleViewButtons] = useState(false);

  const [showLiveChat, setShowLiveChat] = useState(false);
  const [shrinkPlayer, setShrinkPlayer] = useState(false);

  const inactivityTimeout = 3000; // Pin or unpin
  let resizeTimer;

  // Videoplayer settings
  const videoJsOptions = videojsConfig['videoJsOptions'];

  useEffect(() => {
    if (props.streams && props.streams.length > 0) {
      setStreams(props.streams);
    }
  }, [props.streams]);

  // this makes sure the fullscreen button gets reset if the 
  // user escapes rather than uses the fs button
  useEffect(() => {
    const handleEsc = (event) => {
       if (event.keyCode === 27) {
        setIsInFullScreen(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
  // On page load get the performance data and prepare the players
    boot({ alignment: 'left' })

    if (props.performance) {
      getPerformanceConfiguration(props.performance.configurationId); // Get the configuration for that performance
      // getStream(props.performance.id); // Get the streams for that performance
    }

    addToDataLayer({
      'event': "audio-click",
      'button_name': "audio_default",
      'action': 'on',
    });

    addToDataLayer({
      'event': "quality-click",
      'button_name': "Quality Selection",
      'action': 'Auto',
    });

    const players = {
      "main-video": {
        "player": player1,
        "callback": () => { onPlayerReady() },
        "setter": (player) => { setPlayer1(player) },
        "muted": true,
        "fluid": false,
      },
      "second-video": {
        "player": player2,
        "callback": () => { onPlayerReady() },
        "setter": (player) => { setPlayer2(player) },
        "muted": true,
        "fluid": false,
      },
      "subtitle-video": {
        "player": subtitlePlayer,
        "callback": () => { onPlayerReady() },
        "setter": (player) => { setSubtitlePlayer(player) },
        "muted": true,
        "fluid": true,
      },
      "bsl-video": {
        "player": BSLPlayer,
        "callback": () => { onPlayerReady() },
        "setter": (player) => { setBSLPlayer(player) },
        "muted": true,
        "fluid": true,
      },
      "sprite-video": {
        "player": spritePlayer,
        "callback": () => { onPlayerReady() },
        "setter": (player) => { setSpritePlayer(player) },
        "muted": true,
        "fluid": false,
      },
      "audio-feed": {
        "player": spritePlayer,
        "callback": () => { onPlayerReady() },
        "setter": (player) => { setAudioPlayer(player) },
        "muted": true,
        "fluid": false,
      }
    };
    for (const [key, value] of Object.entries(players)) {
      // If there is not player1 defined then create the player
      if (value['player'] === undefined) {
        if ((videojs.browser.IS_ANY_SAFARI || videojs.browser.IS_IOS || (videojs.browser.IS_FIREFOX && videojs.browser.IS_ANDROID)) && key === "sprite-video") {
          videoJsOptions['html5']['vhs']['overrideNative'] = false;
        } else {
          videoJsOptions['html5']['vhs']['overrideNative'] = true;
        }
        // Create the player giving the id from the video, the config and a callback function for when the player is created
        const player = videojs(key, videoJsOptions, () => { value['callback']() });

        setOnError(player);
        if (value["muted"]) {
          player.muted(true);
        }
        if (value["fluid"]) {
          player.fluid(true);
        }
        if (location.pathname.substr(1) === 'demo') {
          player.loop(true);
        }

        if (key === "main-video" || key === "second-video") {
          player.autoplay('muted');
        }
        if (key === "sprite-video") {
          player.autoplay('muted');
        }
        const hlsQualityLevels = player.qualityLevels();
        hlsQualityLevels.on('change', function () {
          if (hlsQualityLevels[hlsQualityLevels.selectedIndex] != undefined)
            console.log(`Quality Level ${player.id()} changed to:`, hlsQualityLevels[hlsQualityLevels.selectedIndex].height);
        });
        value['setter'](player);
      }
    };

    handleResize();
    // Listen for page resize
    window.addEventListener('resize', () => handleResize())
    return _ => {
      window.removeEventListener('resize', () => handleResize())
    }
  }, []);

  useEffect(() => {
    if (player1 !== undefined) {
      const hlsQualityLevels = player1.qualityLevels();

      hlsQualityLevels.on('addqualitylevel', function (event) {
        // qualityLevels
        // console.log(event);
        let qualityLevel = event.qualityLevel;
        setQualityLevel(qualityLevel)
        // console.log([...qualityLevels, qualityLevel.height]);
      });

      // hlsQualityLevels.on('change', function () {
      //     console.log('Quality Level changed to:', hlsQualityLevels[hlsQualityLevels.selectedIndex].height);
      // });

      // hlsQualityLevels.selectedIndex_ = 1;
      // hlsQualityLevels.trigger({ type: 'change', selectedIndex: 1 });
      // qualityLevels.on('change', function () {
      //     console.log('Quality Level changed!');
      //     console.log('New level:', qualityLevels[qualityLevels.selectedIndex]);
      // });
    }
  }, [player1])

  useEffect(() => {
    setQualityLevels([...qualityLevels, qualityLevel]);
  }, [qualityLevel])

  useEffect(() => {
    setPlayingCameraIndex(programStreamIndex);
  }, [programStreamIndex]);

  useEffect(() => {
    if (!showHeader) {
      setIsToggleOn(false);
    }
  }, [showHeader]);

  const setOnError = (player) => {
    player.on('error', function () {
      console.log('There has been an error with the player');
    });
  }

  useEffect(() => {
    calculateShrinkSize();
  }, [toggleCaptions, toggleBSL, pinCameras, shrinkPlayer]);

  // Control the size of the cameras when window is resized. Added a debounce of 100ms to avoid large number of calls
  const handleResize = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      calculateShrinkSize();

      paintCameraLayout();

      if ((videojs.browser.IS_IOS || videojs.browser.IS_ANDROID) || (window.innerHeight > window.innerWidth)) {
        if (window.innerHeight < window.innerWidth) {
          const videos = ["main-video", "second-video", "audio-feed", "sprite-video"];
          videos.forEach((video) => {
            document.getElementById(video).style.paddingTop = videojs.browser.IS_CHROME ? document.documentElement.clientHeight : window.innerHeight + "px";
          });
          document.querySelector('.video-interface').style.height = videojs.browser.IS_CHROME ? document.documentElement.clientHeight : window.innerHeight + "px";
          if (document.documentElement.clientWidth < 992) {
            document.querySelector('.scroll-down').style.display = 'flex';
          }
          setPinCameras(false);
        } else {
          document.querySelector('.scroll-down').style.display = 'none';
          setPinCameras(true);
          setShowCameras(true);
        }
      } else {
        document.querySelector('.scroll-down').style.display = 'none';
      }
    }, 50);
  }

  const createMenuList = () => {
    const menuItems = [];
    let isMenuText = false;
    let menuText = '';
    Object.keys(props.sheetsNode).forEach(key => {
      if (isMenuText) {
        if (/^menuLink.*URL/.test(key)) {
          isMenuText = false;
          menuItems.push({ menuText: menuText, menuURL: props.sheetsNode[key] })
        }
      } else {
        if (/^menuLink.*Text/.test(key)) {
          menuText = props.sheetsNode[key];
          isMenuText = true;
        }
      }
    });
    return menuItems;
  }

  // When user is Idle
  const handleOnIdle = event => {
    if (isToggleOn) return;
    setShowInterface(false);
    setShowHeader(false);
    if (!pinCameras) {
      setShowCameras(false);
    }
  }
  // When user leaves the window/player
  const handleMouseLeave = event => {
    if (isToggleOn) return;
    setShowInterface(false);
    setShowHeader(false);
    if (!pinCameras) {
      setShowCameras(false);
    }
  }

  const handleMouseEnter = event => {
    setShowHeader(true);
    setShowInterface(true);
    if(!simpleView) { setShowCameras(true); }
  }

  // When user is active again
  const handleOnActive = event => { }

  // When user does something
  const handleOnAction = event => {


    // If the event is mousemove then show the interface, however only show the header if the user has moved the mouse over the top
    if (event.type === 'mousemove' && hasSomeParentTheClass(event.target, 'video-interface')) {
      setShowHeader(true);
      setShowInterface(true);
      if(!simpleView) { setShowCameras(true); }
    }

    if (event.type === 'keydown') {
      if (!isNaN(event.key) && !showLoadingSpinner) {
        handleCameraSwitch(event.key - 0);
      }
    }

    // If the event is mousedown, or the start of a tap and it's not done on the header or the bottom interface then toggle the header and the controls
    if ((event.type === 'mousedown' || event.type === 'touchstart') && hasSomeParentTheClass(event.target, 'video-players') && !hasSomeParentTheClass(event.target, 'mobile-controls')) {
      // Set both to the same value as they should appear and disappear at the same time
      setShowHeader(!showHeader);
      setShowInterface(!showHeader);
      if (!pinCameras && !simpleView) {
        setShowCameras(!showHeader);
      }
    }
  };

  // Use idle timer package to track the actions of the user
  useIdleTimer({
    timeout: inactivityTimeout,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
  })

  // Check if the modal is still open. Once is closed then unmute the main video
  useEffect(() => {
    if (!props.modalIsOpen) {
      window.scrollTo(0, 0);
      // player1.muted(false);
      if (!isPlaying(player1)) {
        player1.play();
      }
      if (!isPlaying(spritePlayer)) {
        spritePlayer.play();
      }
      player1.muted(false);
    }
  }, [props.modalIsOpen])

  useInterval(() => {
    syncAllStreams(true, 0);
  }, isEnded ? null : 10000);

  useInterval(() => {
    if (toggleBSL) {
      addToDataLayer({
        'event': "bsl-timer",
        'button_name': 'BSL',
        'minutes': getBSLTimePlayingInMinutes()
      });
    }
    if (toggleCaptions) {
      addToDataLayer({
        'event': "captions-timer",
        'button_name': 'Captions',
        'minutes': getCaptionsTimePlayingInMinutes()
      });
    }
    addToDataLayer({
      'event': "audio-timer",
      'button_name': `audio_${(!toggleAudio ? 'default' : 'alternative').replace(/\s+/g, '_').toLowerCase()}`,
      'minutes': getAudioTimePlayingInMinutes()
    });
    addToDataLayer({
      'event': "quality-timer",
      'button_name': `${selectedQuality}`,
      'minutes': getQualityTimePlayingInMinutes(),
    });
  }, isEnded ? null : 60000);

  // Set the source for the main video, subtitle video and BSL video once the data has been fetched from the API
  useEffect(() => {
    // If there are streams
    if (streams.length > 0) {
      // Check if the player has been set, if the source is the same (Avoid setting multiple times the same value)
      if (player1 !== undefined && initialStreamIndex !== null && player1.src() !== findStreamURL(initialStreamIndex)) {
        player1.src(findStreamURL(initialStreamIndex));
        if (mainVideoPlay) {
          player1.play().then(() => {
            onPlayerBuffered(spritePlayer).then(() => {
              checkSync();
              syncAllStreams(true, 1000);
            }, () => { checkSync(); })
            setTimeout(checkSync, 5000);
          });
        }
      }
      if (spritePlayer !== undefined && buttonsStreamIndex !== null && spritePlayer.src() !== findStreamURL(buttonsStreamIndex)) {
        spritePlayer.src(findStreamURL(buttonsStreamIndex));
        spritePlayer.ready(() => {
          spritePlayer.play().then(() => {
            Processor.doLoad(spritePlayer); // When the sprite player is set run the cropping method to split in multiple cameras
          })
        })
      }
      syncAllStreams(true, 0);
    }
  }, [streams, alternateAudioStreams, initialStreamIndex, subtitleStreamIndex, signingStreamIndex, buttonsStreamIndex]);

  const syncAllStreams = (speed, delay = 500) => { // Sync all the streams
    setTimeout(() => {
      let player = mainVideoPlay ? player1 : player2;
      try {
        if (isPlaying(player) && isPlaying(spritePlayer)) {
          synchroniseStreams(spritePlayer, player, !videojs.browser.IS_ANY_SAFARI ? speed : false, props.useLogs);
        }
        const players = [subtitlePlayer, BSLPlayer, audioPlayer];
        players.forEach((syncPlayer) => {
          if (isPlaying(syncPlayer) && isPlaying(player)) {
            synchroniseStreams(syncPlayer, player, speed, props.useLogs);
          }
        })
      } catch (error) {
        console.log(error);

      }
    }, delay)
  }

  // To execute once the players are created and ready
  const onPlayerReady = () => { syncAllStreams(false, 5000); handleResize() };
  /**
   * Crossfade the video based on the selected player
   * @param  {string} player number of the player that should be playing (1 == Main player, 2 == Second player)
   */
  const audiovideoCrossfade = () => {
    for (let index = 1; index < 11; index++) {
      // Set a controlsTimeout based on the index to gradually reduce or increase the volume
      setTimeout(() => {
        // If it's the turn for the main player then turn the volume up, else turn it down
        if (muteAll === false && mutePlayers === false) {
          if (!mainVideoPlay) { player1.muted(false) }
          if (!secondVideoPlay) { player2.muted(false) }
        }
        player1.volume(
          !mainVideoPlay
            ? player1.volume() + index / 10
            : player1.volume() - index / 10
        );
        // If it's the turn for the second player then turn the volume up, else turn it down
        player2.volume(
          !secondVideoPlay
            ? player2.volume() + index / 10
            : player2.volume() - index / 10
        );

        // Visual transition on the half of the audio crossfade
        if (index === 5) {
          setMainVideoPlay(!mainVideoPlay);
          setSecondVideoPlay(!secondVideoPlay);
          setShowLoadingSpinner(false);
        }
        // If it's the last iteration then pause the player that is not their turn 
        if (index === 10) {
          if (!mainVideoPlay) {
            // Player 1 turn, then pause Player 2
            if (isPlaying(player2)) {
              player2.pause();
              player2.muted(true);
            }
          }
          if (!secondVideoPlay) {
            // Player 2 turn, then pause Player 1
            if (isPlaying(player1)) {
              player1.pause();
              player1.muted(true);
            }
          }
        }
      }, 100 * index);
    }
  };

  /**
   * Check if a player has been buffered if so, execute a callback function. Every 0.35s check if the player has started to play
   * @param  {videojs object} player videojs object with the player selected to check
   */
  const onPlayerBuffered = async (player) => {
    const p = mainVideoPlay ? player1 : player2;
    if (isPlaying(player) && player.id() !== "sprite-video") { // If it's not the sprite video and the passed player is playing
      if (player.id() === "main-video" || player.id() === "second-video") { // If it's playing the main video or the second video
        let p1;
        let p2;
        if (!mainVideoPlay) {
          p1 = player1;
          p2 = player2;
        } else {
          p1 = player2;
          p2 = player1;
        }
        synchroniseStreams(p1, p2, false, props.useLogs).then(() => {
          audiovideoCrossfade();
          syncAllStreams(true, 1000);
        });
      } else {
        synchroniseStreams(player, p, false, props.useLogs).then(() => {
          switch (player.id()) {
            case 'subtitle-video':
              setToggleCaptions(true); // Hide or show the captions
              setShowCaptionsSpinner(false);
              break;
            case 'bsl-video':
              setToggleBSL(true); // Hide or show the BSL
              setShowBSLSpinner(false);
              break;
            case 'audio-feed':
              if (!toggleAudio) {
                player1.muted(true);
                player2.muted(true);
                if (!muteAll) {
                  audioPlayer.muted(false);
                }
                console.log(player1.muted(), player2.muted(), audioPlayer.muted())
                setMutePlayers(true);
              } else {
                audioPlayer.muted(true);
                audioPlayer.pause();
              }
              break;
          }
          syncAllStreams(true, 1000);
          return;
        });
      }
      return;
    } else if (player.id() === "sprite-video" && isPlaying(p)) {
      synchroniseStreams(spritePlayer, p, true, props.useLogs).then(() => {
        syncAllStreams(true, 1000);
      });
      return;
    }
    setTimeout(() => {
      onPlayerBuffered(player); // The player hasn't started playing so we check again in 0.35s
    }, 350);
  };

  /** Calculate how long an user has been watching a camera in seconds
   */
  const getTimePlayingInSeconds = () => {
    const now = new Date();
    const secondsPlayed = (now - currentDate) / 1000;
    setCurrentDate(now);
    return parseInt(secondsPlayed);
  }

  const getBSLTimePlayingInMinutes = () => {
    const now = new Date();
    const secondsPlayed = (now - currentBSLDate) / 1000;
    return parseInt(secondsPlayed / 60);
  }

  const getCaptionsTimePlayingInMinutes = () => {
    const now = new Date();
    const secondsPlayed = (now - currentCaptionsDate) / 1000;
    return parseInt(secondsPlayed / 60);
  }

  const getAudioTimePlayingInMinutes = () => {
    const now = new Date();
    const secondsPlayed = (now - currentAudioDate) / 1000;
    return parseInt(secondsPlayed / 60);
  }

  const getQualityTimePlayingInMinutes = () => {
    const now = new Date();
    const secondsPlayed = (now - currentQualityDate) / 1000;
    return parseInt(secondsPlayed / 60);
  }

  /** Function that search for the camera that should be playing and returns the button object
  */
  const getPlayingCamera = () => {
    let camera;
    Object.keys(buttons).forEach(x => camera = buttons[x].targetStreamIndex === playingCameraIndex ? buttons[x] : camera);
    return camera;
  }


  /**
   * Function that handles the clicks done to the live chat button
   * @param  {event} e
   */
  const handleLiveChatClick = (e) => {
    show()
    e.stopPropagation();
    if (props.useLogs === "0" || props.useLogs === "4") console.log('Live support activated');
  };

  const handleMuteClick = (e) => {
    e.stopPropagation();
    if (!muteAll) {
      player1.muted(true);
      player2.muted(true);
      audioPlayer.muted(true);
    } else {
      if (!toggleAudio) {
        player1.muted(false);
        player2.muted(false);
      } else {
        audioPlayer.muted(false);
      }
    }
    setMuteAll(!muteAll);
    if (props.useLogs === "0" || props.useLogs === "4") console.log('Mute!');
  }

  const handleHideCameras = (e) => {
    e.stopPropagation();
    const el = document.querySelector('.bottom-interface');
    el.setAttribute('data-enabled', !pinCameras);
    setPinCameras(!pinCameras);
  }

  const findStreamURL = (id) => {
    let url = '';
    streams.map((item) => {
      if (item.index == id)
        url = item.url;
    });
    return url;
  }

  const findAlternativeLabel = (id) => {
    let label = '';
    alternateAudioStreams.map((item) => {
      if (item.streamIndex == id)
        label = item.label;
    });
    return label;
  }


  /**
   * Handle the switch between cameras setting the source for the corresponding player
   * and crossfade the audio between players
   * @param  {DOM} target DOM of the element clicked
   * @param  {int} id number of the button selected to get the stream
   */
  const handleCameraSwitch = (id) => {
    const url = findStreamURL(id); // Get the URL to be played next
    // Add data to dataLayer (GTM)
    addToDataLayer({
      'event': "camera-click",
      'button_name': getPlayingCamera().label,
      'minutes': Math.round(getTimePlayingInSeconds() / 60),
      'seconds': getTimePlayingInSeconds()
    });
    if (id !== playingCameraIndex) {  // If the same camera as selected now has been clicked do nothing
      const player = !mainVideoPlay ? player1 : player2; // Choose the player that should be playing
      setShowLoadingSpinner(true); // Show the loading spinner on the camera
      player.src(url); // Change the source
      player.ready(() => {
        player.load(); // Start buffering and loading the content
        player.play().then(() => {
          onPlayerBuffered(player);
          if (selectedQuality !== 'Auto') {
            enableQualityLevel(player.qualityLevels(), selectedQuality);
          }
        }, (error) => { // Play the video automatically
          console.log(error); // Show the error on the console
          // domCamera.remove(); Remove the DOM element to avoid future clicks
          handleResize(); // Resize the cameras
          setShowError(true); // Show the error message
          setShowLoadingSpinner(false); // Remove the loading spinner
          setPlayingCameraIndex(getPlayingCamera().targetStreamIndex); // Set the playing camera index to be one before
          return; // Return to avoid 
        });
      });
      setPlayingCameraIndex(id); // Change the id for the actual camera set to change the class 'active' to that element
    }
  };

  /**
   * Handle the switch between audio setting the source for the corresponding audio player
   * @param  {DOM} target DOM of the element clicked
   */
  const handleAudioFeed = (target) => {
    setToggleAudio(!toggleAudio);
    // audioPlayer.muted(true);
    if (props.useLogs === "0" || props.useLogs === "4") console.log('Switch audio to: ', toggleAudio ? 'default' : 'alternative');
    if (toggleAudio) {
      addToDataLayer({
        'event': "audio-click",
        'button_name': "audio_default",
        'action': 'on',
      });
      alternateAudioStreams.map(item => {
        addToDataLayer({
          'event': "audio-click",
          'button_name': `audio_${(item.label).replace(/\s+/g, '_').toLowerCase()}`,
          'action': 'off',
        });
      })
      if (!muteAll) {
        if (mainVideoPlay) {
          player1.muted(false);
        } else {
          player2.muted(false);
        }
      }
      audioPlayer.muted(true);
      audioPlayer.pause();
      setMutePlayers(false);

    } else {
      addToDataLayer({
        'event': "audio-click",
        'button_name': "audio_default",
        'action': 'off',
      });
      addToDataLayer({
        'event': "audio-click",
        'button_name': `audio_alternative`,
        'action': 'on',
      });
      audioPlayer.src(findStreamURL(alternateAudioStreams[0].streamIndex)) // Subtract 0 to convert to int
      audioPlayer.ready(() => {
        audioPlayer.load();
        audioPlayer.play().then(async () => {
          onPlayerBuffered(audioPlayer);
        });// Play the video automatically
      });
    }
  }

  /**
   * Handle the switch between full screen
   * @param  {DOM} target DOM of the element clicked
   */
   const handleFullScreen = (target) => {
  
    //these functions get upset if its asked to toggle back 
    //from Full Screen if it isnt in full
    //screen so to safer we check every time
    var isFS = (document.fullscreenElement && document.fullscreenElement !== null) ||
    (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
    (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
    (document.msFullscreenElement && document.msFullscreenElement !== null);

    //because the above sets true/null which doesnt work for the button
    if(isFS == null){ isFS=false; }

    if (props.useLogs === "0" || props.useLogs === "4") console.log('Switch fullscreen to: ', isFS ? 'on' : 'off');

    var docElm = document.documentElement;
    if (!isFS) {
        if (docElm.requestFullscreen) {
            docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        } else if (docElm.msRequestFullscreen) {
            docElm.msRequestFullscreen();
        }

        addToDataLayer({
          'event': "fullscreen-click",
          'button_name': "Fullscreen",
          'action': 'on',
        });
    } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        addToDataLayer({
          'event': "fullscreen-click",
          'button_name': "Fullscreen",
          'action': 'off',
        });
    }
    setIsInFullScreen(!isFS);
    }

  /**
   * Handle the caption click to hide or show the subtitles
   * @param  {event} e
   */
  const handleCaptionClick = (e) => {
    e.stopPropagation();
    const el = document.querySelector('.subtitles');
    el.setAttribute('data-enabled', !toggleCaptions);
    setShowCaptionsSpinner(true);
    if (subtitlePlayer !== undefined) {
      // If there is no VideoJS object for the subtitle player do nothing
      if (props.useLogs === "0" || props.useLogs === "4") console.log('Toggle captions: ', !toggleCaptions);
      if (!toggleCaptions) {
        addToDataLayer({
          'event': "captions-click",
          'button_name': "Captions",
          'action': 'on',
        });
        // If subtitles are active
        try {
          subtitlePlayer.src(findStreamURL(subtitleStreamIndex)); // Change the source
          subtitlePlayer.play().then(async () => {
            onPlayerBuffered(subtitlePlayer);
            enableQualityLevel(subtitlePlayer.qualityLevels(), selectedQuality);
          });

        } catch (error) {
          console.log(error);
        }
      } else {
        setShowCaptionsSpinner(false);
        addToDataLayer({
          'event': "captions-click",
          'button_name': "Captions",
          'action': 'off',
        });
        // After some delay pause them to avoid odd looking being paused before the fade
        subtitlePlayer.pause(); // Pause to save bandwidth
        setToggleCaptions(false); // Hide or show the subtitles
      }
    }
  };

  /**
   * Handle the BSL click to hide or show the BSL video
   * @param  {event} e
   */
  const handleBSLClick = (e) => {
    e.stopPropagation();
    const el = document.querySelector('.bsl');
    el.setAttribute('data-enabled', !toggleBSL);
    setShowBSLSpinner(true);
    if (BSLPlayer !== undefined) {
      if (props.useLogs === "0" || props.useLogs === "4") console.log('Toggle BSL: ', !toggleBSL);
      if (!toggleBSL) {
        addToDataLayer({
          'event': "bsl-click",
          'button_name': "BSL",
          'action': 'on',
        });
        const el = document.querySelector('.bsl');
        el.setAttribute('data-shrink', true);
        try {
          BSLPlayer.src(findStreamURL(signingStreamIndex)); // Change the source
          BSLPlayer.play().then(async () => {
            onPlayerBuffered(BSLPlayer);
            enableQualityLevel(BSLPlayer.qualityLevels(), selectedQuality);
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        setShowBSLSpinner(false);
        addToDataLayer({
          'event': "bsl-click",
          'button_name': "BSL",
          'action': 'off',
        });
        // After some delay pause them to avoid odd looking being paused before the fade
        BSLPlayer.pause(); // Pause to save bandwidth
        setToggleBSL(false); // Hide or show the BSL
      }

    }
  };
  /**
     * Enables a quality level in the player
     * @param  {object} Object with the player quality levels - player.qualityLevels()
     * @param  {string} The quality level to enable - High, Medium, Low an Simple
     */
  let enableQualityLevel = (hlsqualityLevels, level) => {
    let selectedQualityIndex = 0;
    setSelectedQuality(level);
    if (hlsqualityLevels.length > 0) {
      for (var i = 0; i < hlsqualityLevels.length; i++) {
        let qualityLevel = hlsqualityLevels[i];
        if (level === 'Auto') {
          qualityLevel.enabled = true;
          selectedQualityIndex = -1;
        } else if (level === 'Low' && qualityLevel.height < 360) {
          qualityLevel.enabled = true;
          selectedQualityIndex = i;
        } else if (level === 'Medium' && qualityLevel.height >= 360 && qualityLevel.height < 720) {
          qualityLevel.enabled = true;
          selectedQualityIndex = i;
        } else if (level === 'High' && qualityLevel.height == 720) {
          qualityLevel.enabled = true;
          selectedQualityIndex = i;
        } else if (level === 'Maximum' && qualityLevel.height == 1080) {
          qualityLevel.enabled = true;
          selectedQualityIndex = i;
        } else if (level === 'Simple') {
          qualityLevel.enabled = true;
          selectedQualityIndex = i;
        }
        else {
          qualityLevel.enabled = false;
          selectedQualityIndex = -1;
        }
      }
      if (hlsqualityLevels.selectedIndex_ !== selectedQualityIndex) {

        hlsqualityLevels.selectedIndex_ = selectedQualityIndex;
        hlsqualityLevels.trigger({
          type: 'change',
          selectedIndex: selectedQualityIndex
        });
      }
    }
  }

  const handleQualitySelect = (target) => {
    addToDataLayer({
      'event': "quality-click",
      'button_name': "Quality Selection",
      'action': target.value,
    });

    [player1, player2, subtitlePlayer, BSLPlayer, spritePlayer].forEach(p => {
      if (p != undefined) {
        const hlsqualityLevels = p.qualityLevels();
        enableQualityLevel(hlsqualityLevels, target.value);
      }
    })

    handleSimpleView(target);
  }

  const handleMultiviewClick = (option) => {
    setShrinkPlayer(option);
    const el = document.querySelector('.MV');
    el.setAttribute('data-shrink', option);
  }

  /**
   * Function that controls of the menu should be shown or not
   * @param  {event} e
   */
  const handleMenuClick = (e) => {
    e.stopPropagation();
    if (props.useLogs === "0" || props.useLogs === "4") console.log('Menu is open: ', !isToggleOn);
    setIsToggleOn(!isToggleOn);
  };

  /**
   * Function that controls if the mobile controls should be shown or not
   * @param  {event} e
   */
  const handleMobileDropdown = (e) => {
    e.stopPropagation();
    if (props.useLogs === "0" || props.useLogs === "4") console.log('Mobile dropdown is open: ', !toggleMobileControls);
    setToggleMobileControls(!toggleMobileControls);
  };

  /**
    * Function that closes the error modal 
    */
  const closeModal = () => {
    if (props.useLogs === "0" || props.useLogs === "4") console.log('Closing error modal');
    setShowError(false);
  }

  /**
    * Function that sets up the simple view
    */
   const handleSimpleView = (target) => {
    let sv = (target.value === 'Simple' ? true : false)
    setSimpleView(sv);
    if (props.useLogs === "0" || props.useLogs === "4") console.log('Setting Simple View:', !simpleView);
    if(sv) {
      setShowCameras(false);
      setSimpleViewButtons(true);
    }
    else  
    {
      setShowCameras(true);
      setSimpleViewButtons(false);
      if (spritePlayer !== undefined && buttonsStreamIndex !== null) {
        spritePlayer.src(findStreamURL(buttonsStreamIndex));
        spritePlayer.ready(() => {
          spritePlayer.play().then(() => {
            Processor.doLoad(spritePlayer); // When the sprite player is set run the cropping method to split in multiple cameras
          })
        })
      }

    }
    //uncomfortable assuming directors cut is always ID 7 so check against the buttons
    var id = buttons.find(item => item.labelShort === 'DC').targetStreamIndex;
    handleCameraSwitch(id);
  }

  return (
    <>
      <div id="video-interface" className={"video-interface"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <div className={"header " + (isToggleOn ? "dropdown-show " : "dropdown-hide ") + (showHeader ? 'show' : 'hidden')}>
          <div className="header-block header-donate">
            <a href={props.sheetsNode.topLeftLinkURL} target="_blank" rel="noopener noreferrer">{props.sheetsNode.topLeftLinkText}</a>
            {props.showName !== 'demo' &&
              <a id='live-chat' className="live-chat-link" onClick={handleLiveChatClick}>
                <span className="icon">
                  <span>Live Chat</span>
                </span>
              </a>
            }
          </div>
          {/* <div className="header-title"><img src={props.sheetsNode.logoImage} alt="" /></div> */}
          <div className={"header-block header-menu" + (isToggleOn ? " open" : " close")} onClick={handleMenuClick}>
            <div className="hamburger-menu" role="button" tabIndex="0" role="button">
              {isToggleOn ?
                <>
                  < CloseIcon />
                  <p className="YoungVicGothicBlack">Close</p>
                </>
                :
                <>
                  <span></span>
                  <span></span>
                  <span></span>
                  <p className="YoungVicGothicBlack">Menu</p>
                </>
              }
            </div>
          </div>
          <div className="header-menu-dropdown">
            <ul>
              {createMenuList().map(item => {
                return (
                  <li key={item.menuText}><a target='_blank' rel="noopener noreferrer" href={item.menuURL} >{item.menuText}</a></li>
                )
              })}
              <li><a target='_blank' rel="noopener noreferrer" href='https://www.youngvic.org/cookie-policy' >Cookie policy</a></li>
              <li><a target='_blank' rel="noopener noreferrer" href='https://www.youngvic.org/privacy-policy' >Privacy policy</a></li>
            </ul>
          </div>
        </div>
        <div className="video-players">
          <div className={"bsl"} data-enabled='false'>
            <div className="player-content">
              {showBSLSpinner && <Loader
                className="spinner"
                type="TailSpin"
                color="#ffc700"
                height={50}
                width={50}
              />}
              <div className={"player " + (toggleBSL ? "show" : "hide")}>
                <div data-vjs-player>
                  <video playsInline aria-hidden="true" id="bsl-video" className="video-js" muted="muted"></video>
                </div>
              </div>
            </div>
          </div>
          <div className={"subtitles"} data-enabled='false' >
            <div className="player-content">
              {showCaptionsSpinner && <Loader
                className="spinner"
                type="TailSpin"
                color="#ffc700"
                height={50}
                width={50}
              />}
              <div className={"player " + (toggleCaptions ? "show" : "hide")}>
                <div data-vjs-player>
                  <video playsInline aria-hidden="true" id="subtitle-video" className="video-js" muted="muted"></video>
                </div>
              </div>
            </div>
          </div>
          <div id="player1-wrapper" className={"main" + (mainVideoPlay ? " show" : " hide")}>
            <div data-vjs-player>
              <video playsInline aria-hidden="true" width="1024px" height="720px" id="main-video" className="video-js"></video>
            </div>
          </div>
          <div id="player2-wrapper" className={"main" + (secondVideoPlay ? " show" : " hide")}>
            <div data-vjs-player>
              <video playsInline aria-hidden="true" width="1024px" height="720px" id="second-video" className="video-js"></video>
            </div>
          </div>
          <div className="sprite hide">
            <div data-vjs-player>
              <video playsInline aria-hidden="true" id="sprite-video" className="video-js" muted="muted"></video>
            </div>
          </div>
          <div>
            <div style={{ display: "none" }} data-vjs-player>
              <video playsInline aria-hidden="true" id="audio-feed" className="video-js" muted="muted"></video>
            </div>
          </div>
          <div className="mobile-controls" >
              {!simpleViewButtons ? <span
              role="button"
              className="mute"
              onClick={handleMuteClick}>
              {!muteAll ?
                < SoundOnIcon />
                :
                < SoundOffIcon />
              }
            </span>:<span/>}
            <span
              className="controls-dropdown"
              onClick={handleMobileDropdown}>
              {!toggleMobileControls ?
                < SettingsIcon />
                :
                < CloseIcon />
              }
            </span>
            <div className={"controls " + (toggleMobileControls ? "show" : "hide")}>
              <span className="title button"> Settings </span>
              <select className="button quality-selector" onChange={(e) => handleQualitySelect(e.target)}>
                <option value='Auto'>Auto Quality</option>
                <option value='Maximum'>Maximum</option>
                <option value='High'>High</option>
                <option value='Medium'>Medium</option>
                <option value='Low'>Low</option>  
                <option value='Simple'>Simple Stream</option>
              </select>
              {(!simpleViewButtons) ? <button
                className={'button alternative-audio ' + (toggleAudio ? "active" : "button")}
                onClick={(e) => handleAudioFeed(e.target)}>
                <span className="icon">
                  <ADIcon />
                </span>
                <span>
                  Audio Desc
                </span>
              </button> : <span/>}
              {(!simpleViewButtons) ? <button
                className={'button alternative-audio ' + (isInFullScreen ? "active" : "button")}
                onClick={(e) => handleFullScreen(e.target)}>
                <span className="icon">
                  <FSIcon />
                </span>
                <span>
                  Full Screen
                </span>
              </button> : <span/>}
              {signingStreamIndex !== null && !simpleViewButtons &&
                <button
                  className={"bsl-link " + (toggleBSL ? "button active" : "button")}
                  onClick={handleBSLClick}>
                  <span className="icon">
                    <BSLIcon></BSLIcon>
                  </span>
                  <span>BSL</span>
                </button>
              }
              {subtitleStreamIndex !== null && !simpleViewButtons &&
                <button
                  className={"captions-link " + (toggleCaptions ? "button active" : "button")}
                  onClick={handleCaptionClick}>
                  <span className="icon">
                    <CaptionsIcon />
                  </span>
                  <span>Captions</span>
                </button>
              }
            </div>
          </div>
        </div>
        <div className="portrait-warning">Turn your phone on its side for the complete experience</div>
        <div className="audio-feed-dropdown">

        </div>
        {/* On mobile the bottom interface shall drop below the video. The Above mobile-controls element will display the control elements */}
        <div className="bottom-interface" data-enabled='false'>
          {/* This live chat element shall disappear on mobile view */}
          <div id='live-chat' className={
            showInterface ? "live-chat show" : "live-chat hide"}
          >
             {(!simpleViewButtons) ? <button
              className={'button alternative-audio ' + (toggleAudio ? "active" : "button")}
              onClick={(e) => handleAudioFeed(e.target)}>
              <span className="icon">
                <ADIcon />
                <span>
                  Audio Desc
                </span>
              </span>
            </button> : <span/>}
            {signingStreamIndex !== null && !simpleViewButtons &&
              <button
                className={"bsl-link " + (toggleBSL ? "button active" : "button")}
                onClick={handleBSLClick}>
                <span className="icon">
                  <BSLIcon></BSLIcon>
                  <span>BSL</span>
                </span>
              </button>
            }
            {subtitleStreamIndex !== null && !simpleViewButtons &&
              <button
                className={"captions-link " + (toggleCaptions ? "button active" : "button")}
                onClick={handleCaptionClick}>
                <span className="icon">
                  <CaptionsIcon />
                  <span>Captions</span>
                </span>
              </button>
            }
          </div>

          <div className={"camera-buttons col-" + (buttons ? buttons.length : "0") + (showCameras ? ' show' : ' hidden')} data-cols={(buttons ? buttons.length : "0")}>
            {!simpleView && (buttons && buttons.length > 0) &&
              buttons.map((item) => {
                if (findStreamURL(item.targetStreamIndex) !== '') {
                  return (
                    <div
                      key={item.targetStreamIndex}
                      className={
                        (item.targetStreamIndex === playingCameraIndex ? "camera active" : "camera") + " " + item.labelShort
                      }
                      id={item.targetStreamIndex}
                      role="button"
                      onClick={(e) => {
                        handleCameraSwitch(item.targetStreamIndex);
                        if (item.labelShort === "MV") {
                          handleMultiviewClick(true);
                        } else {
                          handleMultiviewClick(false);
                        }
                      }}
                      data-shrink={false}
                      data-name={item.label}
                      style={{ order: item.labelShort === "DC" ? 99 : item.targetStreamIndex }}
                    >
                      <canvas
                        playsInline aria-hidden="true"
                        height="144"
                        width="256"
                        id={"canvas " + item.targetStreamIndex}
                        className="camera-canvas"
                        data-positionx={item.positionX}
                        data-positiony={item.positionY}
                        data-height={item.height}
                        data-width={item.width}
                      ></canvas>
                      <span className="camera-text">{item.label}</span>
                      <span className="min-camera-text">{item.labelShort}</span>
                      {item.targetStreamIndex === playingCameraIndex && showLoadingSpinner &&
                        <Loader
                          className="spinner"
                          type="TailSpin"
                          color="#ffc700"
                          height={50}
                          width={50}
                        />
                      }
                    </div>
                  );
                }
              })
            }
          </div>
          {/* These control element shall disappear on mobile view */}

          <div className={"right-side" + (showInterface ? " show" : " hide")} role="button">
            <select className="button quality-selector" onChange={(e) => handleQualitySelect(e.target)}>
              <option value='Auto'>Auto Quality</option>
              <option value='Maximum'>Maximum</option>
              <option value='High'>High</option>
              <option value='Medium'>Medium</option>
              <option value='Low'>Low</option>
              <option value='Simple'>Simple Stream</option>
            </select>
            <button className={"mute button" + (!muteAll ? ' active' : '') + (simpleView ? ' noshow': '')} onClick={handleMuteClick}>
              {!muteAll ?
                <span
                  className="icon mute"
                  onClick={handleMuteClick}>
                  < SoundOnIcon />
                  <span>
                    Sound On
                  </span>
                </span>
                :
                <span
                  className="icon mute"
                  onClick={handleMuteClick}>
                  < SoundOffIcon />
                  <span>
                    Sound Off
                  </span>
                </span>
              }
              </button>
            <button className={"hide-camera button" + (pinCameras ? ' active' : '')+ (simpleView ? ' noshow': '')} onClick={handleHideCameras}>
              {pinCameras ?
                <span
                  className="icon">
                  < UnpinIcon />
                  <span>
                    Unpin Cameras
                  </span>
                </span>
                :
                <span
                  className="icon">
                  < PinIcon />
                  <span>
                    Pin Cameras
                  </span>
                </span>
              }
            </button>
            <button className={"fullscreen button" + (isInFullScreen ? ' active' : '') + (simpleView ? ' noshow': '')} onClick={handleFullScreen}>
              {isInFullScreen ?
                <span
                  className="icon fullscreen">
                  < FullscreenOnIcon />
                  <span>
                    Fullscreen On
                  </span>
                </span>
                :
                <span
                  className="icon fullscreen">
                  < FullscreenOffIcon />
                  <span>
                    Fullscreen Off
                  </span>
                </span>
              } 
              </button>
          </div>
        </div>
        <Modal
          isOpen={showError}
          onRequestClose={closeModal}
          contentLabel="Welcome"
          ariaHideApp={false}>
          <div className="logo">
            <img src={props.sheetsNode.logoImage} alt="" />
          </div>
          <div className="modal-content">
            <div className="modal-text">There has been an error loading the camera</div>
            <button onClick={closeModal} className="modal-button">Close</button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default VideoInterface;
